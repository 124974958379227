import 'twin.macro';
import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import { Container, Heading, Button } from '../../components/atoms';
import { PortableText } from '../../components/elements';

const Wrapper = styled.div`
  svg {
    max-width: 4rem !important;
  }
`;

export const paymentSuccessQuery = graphql`
  query paymentSuccessQuery {
    sanityPayment(slug: { current: { eq: "success" } }) {
      _id
      title
      slug {
        current
      }
      body
      buttonText
      buttonLink
    }
  }
`;

const PaymentSuccessPage = ({ data }) => {
  if (!data) return null;
  const page = data?.sanityPayment;
  return (
    <Layout>
      <Container>
        <Wrapper tw="text-center mt-6">
          <svg viewBox="0 0 24 24" tw="m-auto">
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            />
          </svg>
          <div tw="pb-6 text-center">
            <div className="pb-6">
              {page?.title && <Heading>{page?.title}</Heading>}
              {page?.body && (
                <PortableText tw="!px-0 !py-1">{page.body}</PortableText>
              )}
            </div>
            {page?.buttonLink && (
              <Link to={page.buttonLink || '/shop'}>
                <Button tw="mx-auto">{page.buttonText || 'Shop Now'}</Button>
              </Link>
            )}
          </div>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default PaymentSuccessPage;
